<template>
    <a-modal
            :visible="visible"
            :title='modalName'
            :okText='bannerOkText'
            cancelText="Hủy"
            @cancel="onModalCancel"
            @ok="() => { $emit('create', bannerType) }"
    >
        <a-form layout='vertical' :form="form">
            <a-form-item label='Hình ảnh banner'>
                <a-input type="hidden" v-decorator="[
               'bannerImage',
              {
                rules: [{ required: true, message: 'Banner không được bỏ trống!' }],
              }
            ]"
                />
                <div v-if="!imageSelected" @click="handleShowModal" class="imageSelect">
                    <a-icon style="font-size: 20px; margin-bottom: 10px;" type="picture" />
                    <span style="text-align: center; display: block; font-weight: 500;">Chọn banner</span>
                </div>
                <div  @click="handleShowModal" v-else style="cursor: pointer; display: inline-block; margin: 5px; padding: 5px; border: 1px solid #eee; border-radius: 5px;">
                    <span  :style="`display: inline-block; width: 375px; height: 175px; background-size: cover; background-image: url('${imageSelected.url}')`" />
                </div>
            </a-form-item>
            <a-form-item label='Loại sự kiện cho banner'>
                <a-input type="hidden" v-decorator="['event',
                    {
                        rules: [{ required: true, message: 'Loại sự kiện banner không được bỏ trống!' }]
                    }
                ]"/>
                <a-select v-model="type" placeholder="Chọn sự kiện" style="width: 180px"  @change="handleTypeChange">
                    <a-select-option value="NONE">Không có sự kiện</a-select-option>
                    <a-select-option value="PRODUCT">Dẫn đến sản phẩm</a-select-option>
                    <a-select-option value="CATEGORY">Dẫn đến danh mục</a-select-option>
                    <a-select-option value="LINK">Dẫn đến link</a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item :style="`display: ${valueVisible ? 'block':'none'}`" :label='valueName'>
                <a-input
                        :placeholder="valuePlaceholder"
                        @pressEnter="handleInputValue"
                        v-decorator="[
                          'eventValue',
                          {
                            rules: [{ required: valueVisible, message: 'Vui lòng nhập thông tin' }],
                          }
                        ]"
                />
            </a-form-item>
        </a-form>
        <select-single-image-modal :onSelectImage="onSelectImage" :onCancel="onCancel" :visible="selectImageVisible" />
    </a-modal>
</template>

<script>
    import SelectSingleImageModal from "../modal/SelectSingleImageModal";

    export default {
        name: "BannerForm",
        components: {SelectSingleImageModal},
        props: ['visible', 'bannerType', 'currentBanner'],
        data() {
            return {
                selectImageVisible: false,
                imageSelected: null,
                type: "NONE",
                valueName: '',
                valueVisible: false,
                valuePlaceholder: '',
            }
        },
        methods: {
            handleShowModal() {
                this.selectImageVisible = true;
            },
            onCancel() {
                this.selectImageVisible = false;
            },
            onSelectImage(image) {
                this.form.setFieldsValue({
                    bannerImage: JSON.stringify(image)
                });
                this.imageSelected = image;
                this.selectImageVisible = false;
            },
            removeImageSelected() {
                this.form.resetFields();
                this.form.setFieldsValue({
                    bannerImage: null
                });
                this.imageSelected = null;
            },
            onModalCancel() {
                this.$emit('cancel');
                this.form.resetFields();
                this.form.setFieldsValue({
                    bannerImage: null,
                    event: "NONE",
                    eventValue: undefined
                });
                this.type = "NONE";
                this.valueVisible = false;
                this.imageSelected = null;
            },
            handleTypeChange(type) {
                switch (type) {
                    case "NONE":
                        this.valueVisible = false;
                        break;
                    case "PRODUCT":
                        this.valueVisible = true;
                        this.valueName = "Chọn sản phẩm";
                        this.valuePlaceholder = "Nhập ID sản phẩm...";
                        break;
                    case "CATEGORY":
                        this.valueVisible = true;
                        this.valueName = "Chọn danh mục";
                        this.valuePlaceholder = "Nhập ID danh mục sản phẩm...";
                        break;
                    case "LINK":
                        this.valueVisible = true;
                        this.valueName = "Chọn link";
                        this.valuePlaceholder = "Nhập link...";
                        break;
                }
                if (this.currentBanner && type === this.currentBanner.event) {
                    this.form.setFieldsValue({
                        event: type,
                        eventValue: this.currentBanner.eventValue
                    })
                } else {
                    this.form.setFieldsValue({
                        event: type,
                        eventValue: undefined
                    })
                }
            },
            handleInputValue(e) {
                // eslint-disable-next-line no-console
                console.log(e.target.value);
            }
        },
        watch: {
            visible(value) {
                if (!value) {
                    this.form.resetFields();
                    this.form.setFieldsValue({
                        bannerImage: undefined,
                        event: "NONE",
                        eventValue: undefined
                    });
                    this.type = "NONE";
                    this.valueVisible = false;
                    this.imageSelected = null;
                }
                if (value && this.currentBanner) {
                    this.form.getFieldDecorator('bannerImage', { initialValue: JSON.stringify(this.currentBanner.bannerImage) });
                    this.form.getFieldDecorator('event', { initialValue: this.currentBanner.event === null ? "NONE":this.currentBanner.event });
                    this.form.getFieldDecorator('eventValue', { initialValue: this.currentBanner.eventValue === null ? undefined:this.currentBanner.eventValue });

                    this.form.setFieldsValue({
                        bannerImage: JSON.stringify(this.currentBanner.bannerImage),
                        event: this.currentBanner.event === null ? "NONE":this.currentBanner.event,
                        eventValue: this.currentBanner.eventValue === null ? undefined:this.currentBanner.eventValue
                    });
                    this.type = this.currentBanner.event;
                    this.valueVisible = this.type !== "NONE";
                    this.imageSelected = this.currentBanner.bannerImage;
                }
            }
        },
        computed: {
            modalName() {
                return this.currentBanner ? "Cập nhật banner": "Thêm mới banner"
            },
            bannerOkText() {
                return this.currentBanner ? "Cập nhật": "Thêm mới"
            }
        },
        beforeCreate() {
            this.form = this.$form.createForm(this);
            this.form.getFieldDecorator('bannerImage', { initialValue: undefined });
            this.form.getFieldDecorator('event', { initialValue: "NONE" });
            this.form.getFieldDecorator('eventValue', { initialValue: undefined });
        }
    }
</script>

<style>
    .imageSelect {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 120px;
        height: 120px;
        padding: 20px;
        border-radius: 10px;
        border: 1px dotted rgba(0, 0, 0, 0.2);
        cursor: pointer;
    }
</style>