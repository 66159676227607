<template>
    <a-layout-content class="Question">
        <a-breadcrumb style="margin: 16px 24px">
            <a-breadcrumb-item>
                <router-link :to="{ path: '/' }" class="nav-link">
                    Trang chủ
                </router-link>
            </a-breadcrumb-item>
            <a-breadcrumb-item>
                <router-link :to="{ path: '/' }" class="nav-link">
                    Cài đặt
                </router-link>
            </a-breadcrumb-item>
            <a-breadcrumb-item>{{ this.$route.name }}</a-breadcrumb-item>
        </a-breadcrumb>
        <a-layout :style="{minHeight: 'calc(100vh - 120px)', backgroundColor: '#fff', margin: '15px', padding: '15px' }">
            <h2>Banner Top <a-divider type="vertical" /> <a-button @click="handleAddNewTop" icon="plus" type="primary">Thêm mới</a-button> </h2>
            <a-divider/>
            <a-list :grid="{ gutter: 16, xs: 1, sm: 2, md: 4, lg: 4, xl: 4, xxl: 6 }" :dataSource="bannerTop">
                <a-list-item slot="renderItem" slot-scope="item, index" :key="index">
                    <a-card class="store-action" hoverable>
                        <img
                                alt="example"
                                :src="item.bannerImage.url"
                                slot="cover"
                        />
                        <template class="ant-card-actions store-banner-action" slot="actions">
                            <a-icon type="eye" @click="() => handlePreview(item.bannerImage)" />
                            <a-icon type="edit" @click="() => handleTopEdit(item)" />
                            <a-popconfirm
                                    title="Bạn có chắc chắn muốn xóa banner?"
                                    @confirm="() => deleteBanner(item.id)"
                                    okText="Xóa"
                                    cancelText="Hủy"
                            >
                                <a-icon type="delete" />
                            </a-popconfirm>
                        </template>
                    </a-card>
                </a-list-item>
            </a-list>
            <a-divider/>
            <h2>Banner Giữa <a-divider type="vertical" /> <a-button v-if="bannerMid.length < 1" @click="handleAddNewMid" icon="plus" type="primary">Thêm mới</a-button> </h2>
            <a-divider/>

            <a-list :grid="{ gutter: 16, xs: 1, sm: 1, md: 2, lg: 2, xl: 3, xxl: 4 }" :dataSource="bannerMid">
                <a-list-item slot="renderItem" slot-scope="item, index" :key="index">
                    <a-card class="store-action" hoverable>
                        <img
                                alt="example"
                                :src="item.bannerImage.url"
                                slot="cover"
                        />
                        <template class="ant-card-actions store-banner-action" slot="actions">
                            <a-icon type="eye" @click="() => handlePreview(item.bannerImage)" />
                            <a-icon type="edit" @click="() => handleTopEdit(item)" />
                        </template>
                    </a-card>
                </a-list-item>
            </a-list>
        </a-layout>

        <a-modal :centered="true" :width="700" :visible="previewVisible" :footer="null" @cancel="handleCancel">
            <img alt="example" style="width: 100%" :src="previewImage" />
        </a-modal>
        <banner-form  ref="bannerForm" :visible="visible" @create="handleSaveBanner" @cancel="handleFormCancel" :bannerType="bannerType" :currentBanner="currentBanner" />
    </a-layout-content>
</template>

<script>
    import BannerForm from "../../components/forms/BannerForm";
    import { RepositoryFactory } from "../../repository/RepositoryFactory";
    const bannerRepository = RepositoryFactory.get("banner");
    
    export default {
        components: {BannerForm},
        props: {
        },
        data() {
            return {
                data: [],
                previewVisible: false,
                previewImage: '',
                visible: false,
                bannerType: '',
                currentBanner: null,
                bannerTopDefault: {
                    active: true,
                    delete: false,
                    height: 375,
                    width: 175,
                    sizeX: 375,
                    sizeY: 175,
                    unit: 'pt',
                    name: 'banner top',
                    group_name: 'top'
                },
                bannerMidDefault: {
                    active: true,
                    delete: false,
                    height: 227,
                    width: 62,
                    sizeX: 227,
                    sizeY: 62,
                    unit: 'pt',
                    name: 'banner nhỏ 1',
                    group_name: 'smallbanner1'
                }
            };
        },
        methods: {
            handleCancel() {
                this.previewVisible = false;
            },
            handlePreview(file) {
                this.previewImage = file.url || file.thumbUrl;
                this.previewVisible = true;
            },
            handleFormCancel() {
                this.visible = false;
            },
            handleAddNewTop() {
                this.visible = true;
                this.bannerType = 'top';
                this.currentBanner = null
            },
            handleAddNewMid() {
                this.visible = true;
                this.bannerType = 'smallbanner1';
                this.currentBanner = null;
            },
            handleTopEdit(banner) {
                this.visible = true;
                this.bannerType = banner.group_name;
                this.currentBanner = banner;
            },
            handleSaveBanner(bannerType) {
                this.loading = true;
                let bannerDefault = bannerType === 'top' ? this.bannerTopDefault : this.bannerMidDefault;
                const form = this.$refs.bannerForm.form;
                form.validateFields(async (err, values) => {
                    if (err) {
                        this.loading = false;
                        return;
                    }

                    try {
                        values.bannerImage = JSON.parse(values.bannerImage);
                        let banner = null;
                        if (this.currentBanner === null) {
                            banner = {
                                ...bannerDefault,
                                ...values
                            }
                        } else {
                            banner = {
                                ...this.currentBanner,
                                ...values
                            }
                        }

                        let { data } = await bannerRepository.saveBanner(banner);
                        if (!data.meta.success) throw Error("save banner error!");
                        await this.fetchBanners();
                        if (banner.id > 0) {
                            this.openNotification("Cập nhật banner thành công!", "success");
                        } else {
                            this.openNotification("Thêm mới banner thành công!", "success");
                        }
                    } catch (e) {
                        this.openNotification("Lưu banner bị lỗi! Vui lòng liên hệ nhóm phát triển!", "error")
                    } finally {
                        this.loading = false;
                        this.visible = false;
                    }
                });
            },
            async deleteBanner(id) {
                try {
                    await bannerRepository.deleteBanner(id);
                    this.data = this.data.filter(banner => banner.id !== id);
                    this.openNotification("Xóa banner thành công!", "success");
                } catch (e) {
                    this.openNotification("Xóa banner lỗi! Vui lòng liên hệ nhóm phát triển!", "error")
                }
            },
            openNotification (message, type) {
                this.$notification[type]({
                    message: "Hệ thống",
                    description: message
                });
            },
            async fetchBanners() {
                try {
                    let { data } = await bannerRepository.getAll();
                    if (!data.meta.success) throw new Error("Không thể lấy dữ liệu!");
                    this.data = data.data;
                } catch (e) {
                    this.openNotification("Không thể lấy dữ liệu! Vui lòng liên hệ nhóm phát triển!", "error")
                }
            }
        },
        computed: {
            bannerTop() {
                return this.data.filter( banner => banner.group_name === 'top' );
            },
            bannerMid() {
                return this.data.filter( banner => banner.group_name === 'smallbanner1' );
            }
        },
        async created() {
            await this.fetchBanners();
        }
    };
</script>

<style scoped>
    .store-action {
        position: relative;
    }
    .overlay {
        position: absolute;
        top: 0; left: 0; right: 0; bottom: 0;
    }
    .store-banner-action {
        display: none;
    }
</style>
